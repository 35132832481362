import css from "@emotion/css"
import { Card, CardContent, CardImage, Column, Columns, Title } from "bloomer"
import { graphql, Link } from "gatsby"
import React from "react"

import ImageWrap from "../atoms/image-wrap.atom"

export const ModelsList = ({ models }) => (
  <Columns isMultiline>
    {models &&
      models.map(model => (
        <Column isSize={6} key={model.slug}>
          <Link to={`/models/${model.slug}`}>
            <Card key={model.slug}>
              <CardImage>
                <ImageWrap
                  responsiveStyle={`fixed`}
                  image={model.acf.gallery_image}
                  fadeIn={true}
                  css={css`
                    overflow: hidden;
                    max-width: 100%;
                    display: block;
                  `}
                  placeholderStyle={{
                    backgroundColor: `black`,
                  }}
                />
              </CardImage>
              <CardContent
                css={css`
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  background: rgba(0, 0, 0, 0.4);
                  width: 100%;
                  color: white;
                `}
              >
                <Title>{model.title}</Title>
                <div dangerouslySetInnerHTML={{ __html: model && model.excerpt }} />
              </CardContent>
            </Card>
          </Link>
        </Column>
      ))}
  </Columns>
)

export const modelListQuery = graphql`
  fragment ModelDefaultFields on wordpress__wp_model {
    id
    slug
    content
    title
    status
    excerpt
  }
  fragment ModelDefaultAcfFields on wordpress__wp_modelAcfCustom {
    birthdate
    dress_size
    eyes
    height
    languages
    lingerie
    subtitle
    occupation
  }
  fragment ModelListFields on wordpress__wp_model {
    ...ModelDefaultFields
    acf {
      ...ModelDefaultAcfFields
    }
  }
`
