import css from "@emotion/css"
import styled from "@emotion/styled"
import { Container, Control, Field, Section, Select, Title } from "bloomer"
import { Column } from "bloomer/lib/grid/Column"
import { Columns } from "bloomer/lib/grid/Columns"
import { push } from "gatsby"
import React, { useState } from "react"
import { PageIntro } from "../atoms/page-intro.component"
import { THEMES } from "../config/theme"
import { DEFAULTS } from "../consts/app.constants"
import { DARK, YELLOW } from "../consts/colors.constants"
import Layout from "../layouts"
import { ContentSection } from "./content-section.component"
import { ModelsList } from "./models-list.component"
import PostsList from "./posts-list.component"

const StyledTitle = styled(Title)`
  color: ${YELLOW} !important;
  font-family: Oswald;
  font-size: 36px;
  letter-spacing: 0.64px;
  line-height: 25px;
`

const LocationTemplate = props => {
  const { models, models_category, posts, page, selector_cities } = props.data
  const hasModels = models && models.edges.length > 0
  const [selectedCity, setSelectedCity] = useState(props.location.search)
  const {
    page_intro_show,
    page_intro_text,
    main_title,
    page_intro_button_link,
    page_intro_button_text,
  } = page.acf
  const introTheme = THEMES.dark
  return (
    <Layout
      className={`theme-${page.page_theme}`}
      title={unescape(page.name)}
      isHome={false}
      acf={page.acf}
      themeName={`black`}
      templateName={`template-locations template-locations-${
        hasModels ? `with-models` : `no-models`
      }`}
      {...page}
      page_sloped={hasModels ? `right` : `left`}
      page_sloped_amount={30}
    >
      <PageIntro
        className={`${page_intro_show} page-intro`}
        title={main_title}
        text={page_intro_text || DEFAULTS.PAGE_INTRO}
        button_link={page_intro_button_link}
        button_text={page_intro_button_text}
        transparency={0}
        theme_style={introTheme.name}
        offset={0}
      />
      {hasModels && (
        <Section
          css={css`
            background-color: black;
          `}
        >
          <Container>
            <Columns>
              <Column>
                <StyledTitle
                  isPulled={`left`}
                  dangerouslySetInnerHTML={{ __html: `Escort Models available In ${page.name}` }}
                />
                <Field isPulled={`right`}>
                  <Control>
                    <Select
                      isColor={`primary`}
                      className="has-background-black"
                      value={selectedCity}
                      onChange={e => {
                        setSelectedCity(e.target.value)
                        push(e.target.value)
                      }}
                    >
                      {selector_cities &&
                        selector_cities.edges.map(({ node }) => (
                          <option key={node.id} value={node.path || `/models/${node.slug}`}>
                            {node.name}
                          </option>
                        ))}
                    </Select>
                  </Control>
                </Field>
              </Column>
            </Columns>
            <ModelsList models={models.edges.map(({ node }) => node)} />
          </Container>
        </Section>
      )}
      <ContentSection content_sections={page.acf.content_sections} />
      {models_category && models_category.edges && (
        <Section>
          <Container>
            <StyledTitle
              dangerouslySetInnerHTML={{ __html: `Escort models able to travel to ${page.name}` }}
              css={css`
                text-align: "center";
                width: 100%;
              `}
            ></StyledTitle>
            <ModelsList models={models_category.edges.map(({ node }) => node)} />
          </Container>
        </Section>
      )}
      {posts.edges && (
        <Section
          css={css`
            background-color: ${DARK};
          `}
        >
          <Container>
            <StyledTitle
              css={css`
                text-align: "center";
                width: 100%;
              `}
            >
              Browse News
            </StyledTitle>
            <PostsList posts={posts.edges.slice(0, 3).map(edge => edge.node)}></PostsList>
          </Container>
        </Section>
      )}
    </Layout>
  )
}

export default LocationTemplate
