import { graphql } from "gatsby"
import React from "react"

import LocationTemplate from "../components/location-template.component"

const City = props => <LocationTemplate {...props} />

export default City

export const queryCity = graphql`
  fragment CityContentSectionsContent on wordpress__PAGEAcfCustomContent_sections {
    orientation
    text
    style
    content_section_spacing_top
    content_section_spacing_bottom
    vixy_video
    section_image {
      ...ImageFields
      localFile {
        ...ResponsiveContentImageSquare
      }
    }
  }
  fragment CityContentSections on wordpress__PAGEAcfCustom {
    content_sections {
      ...CityContentSectionsContent
    }
  }
  fragment PageIntro on wordpress__PAGEAcfCustom {
    page_intro_button_link
    page_intro_button_text
    page_intro_show
    page_intro_text
    page_intro_transparency
    page_intro_theme
  }
  fragment PageOptions on wordpress__PAGEAcfCustom {
    page_sloped
    page_sloped_amount
    page_theme
    ...PageIntro
  }
  fragment LocationPageOptions on wordpress__PAGEAcfCustom {
    main_title
    header_image {
      ...ImageFields
      localFile {
        ...ResponsiveImageHD
      }
    }
  }
  fragment SlideshowOptions on wordpress__PAGEAcfCustom {
    page_slider_images_background_position_undefined {
      image {
        ...ImageFields
        localFile {
          ...ResponsiveImageHD
        }
      }
      image_align
    }
    page_slider_images_background_position {
      image {
        ...ImageFields
        localFile {
          ...ResponsiveImageHD
        }
      }
      image_align
    }
    slideshow_height
  }

  fragment CityOptions on wordpress__wp_city {
    acf {
      ...LocationPageOptions
      ...CityContentSections
      ...PageIntro
      ...SlideshowOptions
    }
  }

  fragment ModelDefaultAcfFields on wordpress__wp_modelAcfCustom {
    birthdate
    dress_size
    eyes
    height
    languages
    lingerie
    subtitle
    occupation
  }
  fragment CityFields on wordpress__wp_city {
    id
    slug
    name
    ...CityOptions
  }
  query CityById($id: String, $wordpress_id: Int) {
    page: wordpressWpCity(id: { eq: $id }) {
      ...CityFields
    }
    models: allWordpressWpModel(filter: { city: { eq: $wordpress_id } }) {
      edges {
        node {
          ...ModelListFields
          acf {
            gallery_image {
              localFile {
                ...ModelGalleryImage
              }
            }
          }
        }
      }
    }
    models_category: allWordpressWpModel(filter: { city: { ne: $wordpress_id } }) {
      edges {
        node {
          ...ModelListFields
          acf {
            gallery_image {
              localFile {
                ...ModelGalleryImage
              }
            }
          }
        }
      }
    }
    posts: allWordpressPost(filter: { city: { eq: $wordpress_id } }) {
      edges {
        node {
          ...WPPostFields
          featured_media {
            id
            source_url
            localFile {
              ...PostListimage
            }
          }
        }
      }
    }
    selector_cities: allWordpressWpCity(filter: { acf: { show_in_selector: { eq: true } } }) {
      edges {
        node {
          id
          name
          path
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
